<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import chat from "@/views/pages/chat/chat.vue";

/**
 * Chat component
 */
export default {
  page: {
    title: "Chat",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    chat,
    Layout,
    PageHeader,
  },
}
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <chat v-if="tickets!=null" :tickets="tickets" :user="user"></chat>
  </Layout>
  <!-- End d-lg-flex  -->
</template>
